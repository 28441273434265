// jshint strict:false, undef:false, unused:false

/*/
	FormCarry Ajax Functions
	Last Updated 2019-07-12
/*/

function getUrlString(data) {
	var urlParameters = Object.entries(data).map(function (e) {
		return e.join('=');
	}).join('&');

	return urlParameters;
}

function ajaxFormInit(selector) {
	selector = selector || '[data-processor=formcarry]';
	var debug = getUrlParameter('debug'), successText, errorText;

	switch (document.documentElement.getAttribute("lang")) {
		case "en":
			successText = "Thank you for contacting us. We will be in touch.";
			errorText = "We experienced an error processing your form. Please make sure ALL required fields are complete and try again.";
			break;
		case "es":
			successText = "Gracias por contactarnos. En breve nos comunicaremos con usted.";
			errorText = "Estamos presentando dificultades para procesar su mensaje. Por favor complete todas las areas de información requeridas e intentelo nuevamente.";
			break;
	}

	document.querySelectorAll(selector).forEach(function(form) {
		var action = form.getAttribute("data-post"),
			alert = form.querySelector('[data-alert="status"]'),
			inputs = form.querySelectorAll("[name]"),
			recipient = form.querySelector('[name=recipient]'),
			btn = form.querySelector(".btn-submit");

		// Set default values
		form.setAttribute("method", "POST");
		form.setAttribute("accept-charset", "UTF-8");

		if (recipient) {
			if (debug) {
				console.log("Has Recipient!");
			}

			// Optional: Switch FormCarry action with dropdown
			recipient.addEventListener("change", function(el) {
				action = el.target.options[el.target.selectedIndex].getAttribute("data-action");

				if (action) {
					btn.removeAttribute("disabled");
					form.setAttribute("data-post", action);
				} else {
					btn.setAttribute("disabled", true);
					form.removeAttribute("data-post");
				}

				if (debug) {
					console.log(action);
				}
			});
		}

		// Submit data via Fetch
		form.addEventListener('submit', function(e){
			e.preventDefault();

			var data = getValues(form);

			fetch(action, {
				method: 'POST',
				headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
				body: JSON.stringify(data)
			}).then(function(response) {
				if (debug) {
					console.log(response);
				}
				alert.className = "alert alert-success";
				alert.innerHTML = successText;
			}).catch(function(error) {
				console.log(error);
				alert.className = "alert alert-danger";
				alert.innerHTML = errorText;
			});
		});
	});
}
