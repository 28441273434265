/*/
	Forms
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-05-19
/*/

// Retrieves input data from a form and returns it as a JSON object:
// https://stackoverflow.com/a/49826736

// var form = document.querySelector("footer form")

// Retrieves input data from a form and returns it as a JSON object:
// This might have an issue with checkboxes though?
function formToJSON(elements) {
	return [].reduce.call(elements, function (data, element) {
		data[element.name] = element.value;
		return data;
	}, {});
}

// Same as function above, but doesn't have issues with checkboxes
function getValues(form) {
	var object = {};

	new FormData(form).forEach(function (value, key) {
		console.log(key + ': ' + value);

		if (Object.prototype.hasOwnProperty.call(object, key)) {
			var current = object[key];

			if (!Array.isArray(current)) {
				current = object[key] = [current];
			}

			current.push(value);
		} else {
			object[key] = value;
		}
	});

	return object;
}

function getQueries(form) {
	return Object.values(getValues(form)).flat().filter(d => d).map(e => e.toLowerCase());
}

// Get Shopify Friendly URL String
function getUrlString(data) {
	return Object.entries(data).map(e => e.join('=')).join('&');
}
