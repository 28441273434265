// jshint strict:false, undef:false, unused:false

/*/
	Get URL Parameter (Pure JS, ES5)
	Returns the requested parameter value from the URL string
	Stephen Ginn, 2020-05-19

	Usage: getUrlParameter("name");
/*/


function getUrlParameter(query) {
	return location.search.substr(1).split('&').reduce(function(query, item) {
		item = item.split('=');
		query[item[0]] = item[1];
		return query;
	}, {})[query];
}
